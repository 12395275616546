/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Link } from "react-router-dom";
import React, { useContext, useEffect } from "react";

import CartImg from "../../../assets/images/icon/cart.svg";
import LogoImage from "../../../assets/images/logo.svg";
import { FoodContext } from "../../../contexts/FoodContext";
import FOOD_ACTIONS from "../../../actions/food";
import { DynamicRoutes } from "../../../constants/routes";

import { appService } from "../../../services/appService";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const HeaderLeft = ({
  goBack,
  isLogoLargeAndCentered,
  logo,
  logoClasses,
  router,
}) => (
  <>
    <div className="right_angle">
      {router == `${DynamicRoutes.ClientRoot}foodmenu` ? (
        <Link to={DynamicRoutes.ClientRoot} className="pl-2">
          <i className="fa fa-angle-left" aria-hidden="true" />
        </Link>
      ) : (
        <Link to="#back" onClick={goBack} className="pl-2">
          <i className="fa fa-angle-left" aria-hidden="true" />
        </Link>
      )}
      {/* <Link to="#back" onClick={goBack} className="pl-2">
        <i className="fa fa-angle-left" aria-hidden="true" />
      </Link> */}
    </div>
    <div
      className={classNames([
        { "logo pl_logo": isLogoLargeAndCentered },
        { "ml-4": !isLogoLargeAndCentered },
      ])}
    >
      <Link to={DynamicRoutes.ClientRoot}>
        <img src={logo} alt="logo" className={logoClasses} />
      </Link>
    </div>
  </>
);

export default function FoodHeader() {
  const tenantData = appService.getTenantData();
  const Logo = tenantData?.imageUrl || tenantData?.guestAppLogoUrl || LogoImage;
  const { cartCount, dispatch } = useContext(FoodContext);
  const isLogoLargeAndCentered =
    tenantData.clientSettings?.logoLargeAndCentered;
  const router = useLocation();
  const logoClasses = `dynamic_logo ${
    isLogoLargeAndCentered && "dynamic_logo--large"
  }`;

  useEffect(() => {
    dispatch({ type: FOOD_ACTIONS.SET_CART_COUNT });
  }, [cartCount]);

  const goBack = (e) => {
    e.preventDefault();
    appService.goBack();
  };

  return (
    <>
      <header className="header_area">
        <div className="header-row">
          {!isLogoLargeAndCentered ? (
            <div>
              <HeaderLeft
                goBack={goBack}
                router={router.pathname}
                isLogoLargeAndCentered={isLogoLargeAndCentered}
                logo={Logo}
                logoClasses={logoClasses}
              />
            </div>
          ) : (
            <HeaderLeft
              goBack={goBack}
              router={router.pathname}
              isLogoLargeAndCentered={isLogoLargeAndCentered}
              logo={Logo}
              logoClasses={logoClasses}
            />
          )}
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Link to={DynamicRoutes.Checkout}>
              <div className="cart">
                <img src={CartImg} alt="cart" />
                <p>{cartCount}</p>
              </div>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}
