import React from "react";
import Hotel from "../../assets/images/img/Maya-Manor-Boutique-Hotel-EDITED .png";
import CurveLine from "../../assets/images/icon/line.svg";
import Info from "../../assets/images/icon/info.svg";
import "./greetigCard.scss";

const GreetingCard = ({ isLogin, logo }) => {
  return (
    <section className="row">
      <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <img
          style={{
            height: "90px",
            width: "192px",
            // filter: "grayscale(100%)",
            objectFit: "contain",
          }}
          src={logo ? logo : Hotel}
          about="Hotel"
          className="flex-1"
        />
        {isLogin ? (
          <div className="card_title">
            <h2>Authenticate</h2>
            <h1>your hotel details.</h1>
            <div className="sub_title">
              <img src={CurveLine} about="Hotel" className="flex-1" />
              <div>
                <span>
                  Authenticate your hotel details.{" "}
                  <img src={Info} about="infos" className="flex-1" />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="card_title">
            <h1>Experience</h1>
            <h1>our new virtual</h1>
            <h2
              style={{
                fontWeight: 300,
              }}
            >
              concierge.
            </h2>
            <div
              style={{
                marginTop: "26px",
              }}
              className="sub_title  sub_title_home_page "
            >
              <div className="line"></div>
              <div>
                <span>Our services are now just a tap away.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default GreetingCard;
