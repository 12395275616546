import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

import Gift from "../../assets/images/icon/gift.gif";
import { getAvendiLocalUrl } from "../../helpers/url.helper";
import { getValue } from "../../utils/object";

const OfferModal = (props) => {
  const handleCloseModalClick = (e) => {
    e.stopPropagation();
    props.closeModal();
  };

  const handleModalClick = (e) => {
    // eslint-disable-next-line no-undef
    const env = process.env.REACT_APP_ENV;

    if (env === "production") {
      props.onOfferModalClickClick();
    }

    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const roomNumber = getValue(currentUser, "roomNumber");

    const url = getAvendiLocalUrl({
      env: env,
      hotelName: props.tenantId,
      roomNumber: roomNumber ? parseInt(roomNumber, 10) : "",
    });

    handleCloseModalClick(e);
    window.open(url, "_blank");
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        modalClassName="avendi-modal"
        className="popup_area show"
        centered
        size={"lg"}
        onClick={handleModalClick}
      >
        <ModalBody style={{ padding: "15px 10px", fontFamily: "Inter" }}>
          <div
            className="d-flex justify-content-end"
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <button
              type="button"
              className="border-0 d-flex justify-content-center align-items-center"
              data-dismiss="modal"
              aria-label="Close"
              title="Cancel"
              onClick={handleCloseModalClick}
              style={{
                width: "40px",
                height: "40px",
                background: "black",
                borderRadius: "100%",
              }}
            >
              <IoMdClose
                className=" text-white"
                style={{ fontSize: "30px" }}
                onClick={handleCloseModalClick}
              />
            </button>
          </div>

          <div className="d-flex">
            <img
              src={Gift}
              alt=""
              style={{
                width: "40px",
                height: "40px",
                filter: "grayscale(100%)",
              }}
            />

            <div className="pl-2 mt-10">
              <h2
                className=" mt-2 text-black"
                style={{ fontSize: "14.39px", color: "black", fontWeight: 600 }}
              >
                Exclusive Winter Offer
              </h2>

              <p
                style={{
                  color: "#8F8F8F",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
                className="mt-2"
              >
                Shop now and enjoy and instant Rs.500 discount when your cart
                total exceeds Rs.1500 in the Gifts & Souvenirs section.
              </p>
              <p
                style={{
                  color: "#8F8F8F",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
                className="mt-3"
              >
                Click below to apply the offer and checkout now!
              </p>
            </div>
          </div>

          <div className="d-flex ha_button login_button">
            <button
              style={{
                borderRadius: "0px",
                width: "100%",
                height: "50px",
                backgroundColor: "black",
                fontSize: "14px",
                fontWeight: 400,
              }}
              onClick={handleModalClick}
            >
              Apply Offer & Shop Now
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

OfferModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onOfferModalClickClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  tenantId: PropTypes.string.isRequired,
};

export default OfferModal;
