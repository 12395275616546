export const AVENDI_HOTEL_CONFIG = {
  "prachet.sharma": {
    address: "Rem.work Kamal Pokahri, Kathmandu",
  },
  "avendi.development": {
    address: "Rem.work Kamal Pokahri, Kathmandu",
  },
  "avendi.production": {
    address: "Rem.work Kamal Pokahri, Kathmandu",
  },
  "ambassador.lazimpat": {
    address: "Hotel Ambassador Lazimpat Rd, Kathmandu",
  },
  "barahi.kathmandu": {
    address: "Hotel Barahi Tridevi Sadak, Kathmandu",
  },
  "gokarna.kathmandu": {
    address: "Gokarna Forest Resort Rajnikunj, Kathmandu",
  },
  "kathmanduguesthouse.thamel": {
    address: "Kathmandu Guest House Saathgumti-16, Kathmandu",
  },
  "mayamanor.naxal": {
    address: "Maya Manor Boutique Hotel Hattisar Sadak, Kathmandu",
  },
  "nomad.lazimpat": {
    address: "Nomad Hotel Lazimpat Kumari Mai Marg, Kathmandu",
  },
  "parkvillage.budanilkantha": {
    address: "Park Village Resort Budhanilkantha, Kathmandu",
  },
  "shanker.lazimpat": {
    address: "Hotel Shanker - Palatial Heritage, Kathmandu",
  },
  "siddharthaboudha.hotel": {
    address: "Siddhartha Boutique Boudha Rd, Kathmandu",
  },
  "yaknyeti.durbarmarg": {
    address: "Hotel Yak & Yeti Durbar Marg, Kathmandu",
  },
};
