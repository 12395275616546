import React from "react";
import "./NavigationCard.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/images/icon/arrow-up-right.svg";
import { ReactComponent as BlackArrowIcon } from "../../assets/images/icon/arrow_up_black.svg";

import ArrowImg from "../../assets/images/icon/arrow.png";
import ArrowupImg from "../../assets/images/icon/arrow-up-right.svg";

export const NavigationCard = (props = {}) => {
  const {
    image,
    label,
    link,
    css,
    isBuyGift = false,
    showArrow = true,
    classic = false,
    showBackground = true,
    overrideBackgroundImage,
    hidden = false,
    horizontal = false,
    horizontalWithTransparentBg = false,
    description,
    fixedToBottomOnMobile = false,
    onClick = () => {},
  } = props;

  if (hidden) {
    return null;
  }

  if (classic) {
    return (
      <div className="col-6">
        <Link to={link}>
          <div className="single_tabs_items">
            <div className="tabs_items_img">
              <img src={image} alt={label} />
              <h4>{label}</h4>
            </div>
            <div className="tabs_items_icon">
              <span>
                <img src={ArrowImg} alt="arrow" />
              </span>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (horizontal) {
    return (
      <div
        className={classNames(`col-12 navigation-card y-stacked ${css}`, {
          "xs-position-fixed-bottom": fixedToBottomOnMobile,
        })}
      >
        {isBuyGift ? (
          <a
            style={{
              marginTop: "6px",
              padding: "30px 15px",
              backgroundColor: "#FBFBFB",
              display: "block",
              fontSize: "18px",
            }}
            className=""
            // href={link}
            onClick={onClick}
            target="_blank"
          >
            <span
              style={{
                display: "flex",
                alignItems: " center",
                justifyContent: " space-between",
              }}
              className="link-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <img
                  src={image}
                  alt={label}
                  style={{
                    width: "40px",
                    height: "40px",
                    filter: "grayscale(100%)",
                  }}
                />
                <div>
                  <p
                    style={{
                      fontSize: "14.39px",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {label}
                  </p>
                  <h5
                    style={{
                      maxWidth: "180px",
                      color: "#8F8F8F",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      marginTop: " 5px",
                    }}
                  >
                    {description}
                  </h5>
                </div>
              </div>
              <span
                style={{
                  background: "black",
                }}
                className="social_arrow"
              >
                <img src={ArrowupImg} alt="arrow" />
              </span>
            </span>
          </a>
        ) : (
          <Link
            style={{
              marginTop: "6px",
              padding: "30px 15px",
              backgroundColor: "#FBFBFB",
              display: "block",
              fontSize: "18px",
            }}
            className=""
            to={link}
          >
            <span
              style={{
                display: "flex",
                alignItems: " center",
                justifyContent: " space-between",
              }}
              className="link-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <img
                  src={image}
                  alt={label}
                  style={{
                    width: "40px",
                    height: "40px",
                    filter: "grayscale(100%)",
                  }}
                />
                <div>
                  <p
                    style={{
                      fontSize: "14.39px",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {label}
                  </p>
                  <h5
                    style={{
                      maxWidth: "180px",
                      color: "#8F8F8F",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      marginTop: " 5px",
                    }}
                  >
                    {description}
                  </h5>
                </div>
              </div>
              <span
                style={{
                  background: "black",
                }}
                className="social_arrow"
              >
                <img src={ArrowupImg} alt="arrow" />
              </span>
            </span>
          </Link>
        )}
      </div>
    );
  }

  if (horizontalWithTransparentBg) {
    return (
      <div
        style={{
          fontFamily: "Inter",
        }}
        className={classNames(`col-12 navigation-card y-stacked ${css}`, {
          "xs-position-fixed-bottom": fixedToBottomOnMobile,
        })}
      >
        <Link
          style={{
            backgroundColor: "transparent",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
          className={classNames(`navigation-card__container `)}
          to={link}
        >
          <span className="link-container">
            <div>
              <p
                style={{
                  fontSize: "14.39px",
                  fontWeight: 600,
                }}
              >
                {label}
              </p>
              <h5
                style={{
                  color: "#8F8F8F",
                  maxWidth: "220px",
                }}
              >
                {description}
              </h5>
            </div>
            <span
              className="social_arrow"
              style={{
                backgroundColor: "white",
              }}
            >
              <BlackArrowIcon />
              {/* <img src={ArrowImg} alt="arrow" /> */}
            </span>
          </span>
        </Link>
      </div>
    );
  }

  return (
    <div className="col-6 navigation-card xy-stacked">
      <div className={classNames(`navigation-card__container ${css}`)}>
        {isBuyGift ? (
          <a href={link} onClick={onClick} target="_blank">
            <span className="link-container">
              <img className="main-image" src={image} alt={label} />

              <p
                style={{
                  fontSize: "14.39px",
                }}
              >
                {label}
              </p>
              <h5>{description}</h5>
              <span className="social_arrow">
                <img src={ArrowImg} alt="arrow" />
              </span>
            </span>
            <div
              className="navigation-card__background"
              style={{
                background: `url(${image})`,
              }}
            />
          </a>
        ) : (
          <Link to={link}>
            <span
              style={{
                fontFamily: "Inter",
              }}
              className="link-container"
            >
              <img
                style={{ filter: "grayscale(100%)" }}
                className="main-image"
                src={image}
                alt={label}
              />

              <p>{label}</p>
              <h5>{description}</h5>

              <span
                className="social_arrow"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <BlackArrowIcon />

                {/* <img src={ArrowImg} alt="arrow" /> */}
              </span>
            </span>
            <div
              className="navigation-card__background"
              style={{
                background: `url(${image})`,
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default NavigationCard;
