import { AVENDI_HOTEL_CONFIG } from "../configs/hotel.config";
import { getValue } from "../utils/object";

export const getAvendiLocalUrl = ({ env, hotelName, roomNumber }) => {
  const hotelAddress = getValue(AVENDI_HOTEL_CONFIG[hotelName], "address", "");
  if (env === "production") {
    return `https://np-local.avendi.me/?address=${hotelAddress}&room=${roomNumber}`;
  } else if (env === "staging") {
    return `https://np-local.staging.avendi.me/?address=${hotelAddress}&room=${roomNumber}`;
  }
  return `http://localhost:3001/?address=${hotelAddress}&room=${roomNumber}`;
};
