import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import WebImg from "../../assets/images/img/web.svg";
// import MonaLogo from '../../assets/images/img/mona.png';
// import MapsAndParkingImg from '../../assets/images/icon/map.svg';
import Gift from "../../assets/images/icon/gift.gif";

import ArrowImg from "../../assets/images/icon/arrow-up-right.svg";
import AuthHeader from "./authHeader";
import Footer from "../partials/Footer";
import { DynamicRoutes } from "../../constants/routes";
import useAnalyticsEventTracker from "../../hooks/analytics.hooks";
import { listPublicModule } from "../../services/manageModuleService";
import { getValue } from "../../utils/object";
import GreetingCard from "./GreetingCard";
import PoweredBy from "../partials/PoweredBy";
import { appService } from "../../services/appService";
import { getAvendiLocalUrl } from "../../helpers/url.helper";
// import { appService } from '../../services/appService';

export default function Home({ tenantId }) {
  const [module, setModule] = useState([]);

  const { t } = useTranslation();

  const translation = t("landing");

  // const { clientSettings } = appService.getTenantData();
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    listPublicModule(setModule);
  }, []);

  const getModuleStatus = (modules, moduleName) =>
    modules
      ?.filter((item) => item.modulesName === moduleName)
      .map((item) => item.isActive);

  const marketplace = getModuleStatus(module, "marketplace");
  const shouldShowAvendiLocal = marketplace ? marketplace[0] : false;

  const data = appService.getTenantData();

  const onGiftSouvenirsClick = () => {
    const url = getAvendiLocalUrl({
      // eslint-disable-next-line no-undef
      env: process.env.REACT_APP_ENV,
      hotelName: getValue(data, "username"),
      roomNumber: "",
    });
    gaEventTracker({
      eventName: "Local_Avendi_Hotel_Home_Redirects",
      category: "User",
      action: `Local Avendi Redirects`,
      label: `${tenantId} - Local Avendi Home Redirects`,
    });
    window.open(url, "_blank");
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <AuthHeader />
      <section
        className=""
        style={{
          fontFamily: "Inter",
        }}
      >
        <div className="container">
          <GreetingCard logo={getValue(data, "imageUrl")} />

          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                  <div className="platform">
                    {/* <p>{getValue(translation, "title")}</p> */}
                    <div className="platform_items_box">
                      <div className="row">
                        {/* single items */}
                        <div
                          className="col-6"
                          style={{
                            padding: "0px",
                            paddingRight: "3px",
                          }}
                        >
                          <div
                            className="platform_items"
                            style={{ height: "100%" }}
                          >
                            <Link
                              to={DynamicRoutes.Login}
                              style={{
                                height: "100%",
                                backgroundColor: "#FBFBFB",
                              }}
                            >
                              <span className="platform_sitems">
                                <span className="social_logo">
                                  <img
                                    src={WebImg}
                                    alt="Web"
                                    style={{ filter: "grayscale(100%)" }}
                                  />
                                </span>
                                {/* confirm this change */}
                                <p className="title">
                                  {" "}
                                  {getValue(translation, "cards.home.title")}
                                </p>
                                <h5
                                  style={{ fontSize: "13px", color: "#8F8F8F" }}
                                >
                                  {getValue(
                                    translation,
                                    "cards.home.description"
                                  )}
                                </h5>
                                <span
                                  className="social_arrow"
                                  style={{
                                    backgroundColor: "black",
                                  }}
                                >
                                  <img
                                    src={ArrowImg}
                                    alt="arrow"
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                </span>
                              </span>
                            </Link>
                          </div>
                        </div>

                        {shouldShowAvendiLocal ? (
                          <div
                            className="col-6"
                            style={{
                              padding: "0px",
                              paddingLeft: "3px",
                            }}
                          >
                            <div
                              className="platform_items"
                              style={{ height: "100%" }}
                            >
                              <a
                                // href="https://np-local.avendi.me"
                                target="_blank"
                                onClick={onGiftSouvenirsClick}
                                style={{ height: "100%" }}
                                rel="noreferrer"
                              >
                                <span className="platform_sitems">
                                  <span className="social_logo">
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        filter: "grayscale(100%)",
                                      }}
                                      src={Gift}
                                      alt="Web"
                                    />
                                  </span>
                                  {/* confirm this change */}
                                  <p className="title">
                                    {getValue(
                                      translation,
                                      "cards.souvenirs.title"
                                    )}
                                  </p>
                                  <h5
                                    style={{
                                      fontSize: "13px",
                                      color: "#8F8F8F",
                                    }}
                                  >
                                    {getValue(
                                      translation,
                                      "cards.souvenirs.description"
                                    )}
                                  </h5>
                                  <span
                                    className="social_arrow"
                                    style={{
                                      backgroundColor: "black",
                                    }}
                                  >
                                    <img src={ArrowImg} alt="arrow" />
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* single items */}
                        {/* {clientSettings?.enableMuseumModule && (
                      <div className="col-6">
                        <div className="platform_items">
                          <Link to={DynamicRoutes.Museum}>
                            <span className="platform_sitems">
                              <span className="social_logo">
                                <img src={MonaLogo} alt="Museum" />
                              </span>
                              <p>Museum Services</p>
                              <span className="social_arrow">
                                <img src={ArrowImg} alt="arrow" />
                              </span>
                            </span>
                          </Link>
                        </div>
                      </div>
                    )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            <PoweredBy />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
