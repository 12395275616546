import React from "react";
import { Link, useHistory } from "react-router-dom";
import LogoImage from "../../assets/images/logo.svg";

import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";

export default function BlankLayoutWithLogo(props) {
  const tenantData = appService.getTenantData();
  const history = useHistory();
  const Logo =
    tenantData?.imageUrl ||
    props.logo ||
    tenantData.guestAppLogoUrl ||
    LogoImage;
  const isLogoLargeAndCentered =
    tenantData.clientSettings?.logoLargeAndCentered;

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <header
        className="header_area"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className={isLogoLargeAndCentered && "header-row"}>
          <div
            className={`right_angle ${
              !isLogoLargeAndCentered && "d-inline-block"
            }`}
          >
            <Link to="#back" onClick={goBack} className="pl-2">
              <i className="fa fa-angle-left" aria-hidden="true" />
            </Link>
          </div>
          <div
            className={`${!isLogoLargeAndCentered && "d-inline-block ml-4"}`}
          >
            <Link to={DynamicRoutes.ClientRoot}>
              <img
                src={Logo}
                alt="logo"
                className={`dynamic_logo ${
                  isLogoLargeAndCentered && "dynamic_logo--large"
                }`}
                // style={
               
                // }
              />
            </Link>
          </div>
          <div />
        </div>
      </header>
      {props.children}
    </>
  );
}
